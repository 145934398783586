import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToasterService } from './toaster.service';
import { WebsocketService } from './websocket.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  private readonly noContentTypeUrls = [
    'api/image-upload',
    'api/vca-documents-upload',
    'api/email-templates',
    'api/document-upload'
  ];

  constructor(
    private auth: AuthService,
    private router: Router,
    private toastService: ToasterService,
    private ws: WebsocketService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiReq = req.clone({
      url: req.url.includes('https://timberwarriors.s3.amazonaws.com') ? req.url : `${environment.baseUrl}/${req.url}`,
    });

    if (!this.auth.guest()) {
      let headers = new HttpHeaders({
        Authorization: `Bearer ${this.auth.token()}`,
        Accept: 'application/json'
      });

      if (!this.noContentTypeUrls.includes(req.url) && !req.url.includes('https://timberwarriors.s3.amazonaws.com')) {
        headers = headers.set('Content-Type', 'application/json');
      }

      apiReq = apiReq.clone({ headers });
    }

    return next.handle(apiReq).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse && event.body.message) {
            this.toastService.show('success', '', event.body.message);
          }
        },
        error => {
          const errorMessage = error?.error?.message || 'Internal server error';
          if (error.status === 422) {
            this.toastService.show('error', 'Error!', errorMessage || 'Please correct the highlighted inputs.');
          } else if (error.status === 406) {
            this.toastService.show('error', 'Error!', errorMessage);
          } else if (error.status === 401) {
            this.auth.logout();
            this.ws.close();
            setTimeout(() => this.router.navigate(['']), 500);
            this.toastService.show('error', 'Error!', 'Your session has expired. Please relogin' || errorMessage);
          } else if (error.error) {
            this.toastService.show('error', 'Error!', errorMessage);
          }
        }
      )
    );
  }
}
