import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { BehaviorSubject } from "rxjs";
import { LoaderService } from "./loader";
import { ToasterService } from "./toaster.service";

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    claimData = new BehaviorSubject(null);
    pdfData = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private loader: LoaderService,
        private toaster: ToasterService,
        private auth: AuthService) {
    }

    //for getting data of xactimates,receivables, gallary data etc
    getData(url: string, params: any) {
        return this.http.get(url, { params });
    }

    //for getting single record for xactimate ,recievables,crew list, claim document
    getSingleRecord(url: string) {
        return this.http.get(url);
    }

    //for save and update data
    getSaveAndUpdateData(url: string, data: any) {
        return this.http.post(url, data);
    }

    // get user permission based on id
    getUserPermission() {
        return this.http.get(`api/user-permissions/${this.auth.user().id}`);
    }

    //for delete record
    delete(url: string) {
        return this.http.delete(url);
    }

    //commom api for get claim data
    getClaimData(claim_id: number, customer_id: number) {
        this.loader.state(true);
        this.http.get(`api/document-data/${claim_id}/${customer_id}`).subscribe((data: any) => {
            this.loader.state(false);
            this.claimData.next(data.data);
        }, error => {
            this.loader.state(false);
            this.toaster.show('error', 'Error!', '' + error.error.message);
        })
    }

    //commom api for save wa form 
    saveWaForm(claim_id: number, formData: any) {
        this.loader.state(true);
        this.http.post(`api/customer-document-submission/${claim_id}`, formData).subscribe((data: any) => {
            this.loader.state(false);
            this.toaster.show('success', '', '' + data.messages + '');
            this.pdfData.next(data?.data?.document);


        }, error => {
            this.loader.state(false);
            this.toaster.show('error', 'Error!', '' + error.error.message);
        })
    }

}