import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ToasterService} from './toaster.service';
import * as moment from 'moment/moment';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  currentpage = 1;
  to = 0;
  total = 0;
  notificationData: any;
  count = new BehaviorSubject<{}>({count: 0, notification: ''});

  constructor(private api: ApiService, private toaster: ToasterService, private sanitizer: DomSanitizer) {
  }

  ReadNotifications(id?: number) {
    const url = id ? `api/notification/read/${id}` : `api/notification/read`;

    this.api.getSingleRecord(url).subscribe(
      (data: any) => {
        this.notifiationCount({count: data?.data?.unread_count});
        this.getAllNotification();
      },
      error => {
        this.toaster.show('error', 'Error!', '' + error.error.message);
      });
  }

  notifiationCount(s: any) {
    this.count.next(s);
  }

  getAllNotification() {
    let params: any = {
      page: this.currentpage,
      size: 20,
    };
    this.api.getData(`api/notification-list`, params).subscribe((data: any) => {
      this.notificationData = data.data.data;
      this.notificationData.forEach((element: any) => {
        element.daysAgo = moment(element.created_at).fromNow();
        if (element?.module == 'Claim_Chat') {
          const title = element.body;
          element.body = this.sanitizer.bypassSecurityTrustHtml(element.title);
          element.title = title;
        } else if (element?.type == 'USER_TAG_NOTIFY') {
          const demo = element.body;
          element.body = this.sanitizer.bypassSecurityTrustHtml(element.title);
          element.title = this.sanitizer.bypassSecurityTrustHtml(demo);
        } else {
          element.body = this.sanitizer.bypassSecurityTrustHtml(element.body);
        }
      });
      this.currentpage = data.data.current_page;
      this.total = data.data.total;
      this.to = data.data.per_page;
    }, error => {
      this.toaster.show('error', 'Error!', '' + error.error.message);
    });
  }
}