// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //baseUrl: 'http://localhost:8083',
  baseUrl: 'https://vcatw.com',
  attachementUrl: 'https://timberwarriors.s3.amazonaws.com/dev/original',
  mediaUrl: 'https://timberwarriors.s3.amazonaws.com/vca/claim-photos',
  assignmentMediaUrl: 'https://timberwarriors.s3.amazonaws.com/vca/member-assignment/assignment-photos',
  HOmediaUrl: 'https://timberwarriors.s3.amazonaws.com/vca/customer/insured-photo',
  documentUrl: 'https://timberwarriors.s3.amazonaws.com/vca',
  waDocuments: 'https://timberwarriors.s3.amazonaws.com/vca/customer/work-authorization',
  cosDocuments: 'https://timberwarriors.s3.amazonaws.com/vca/customer/certificate-of-satisfaction',
  profileImg: 'https://timberwarriors.s3.amazonaws.com/vca/super-admin/users-profile-pics',
  superAdminUrl: 'https://timberwarriorsapp.com/VcaSuperAdmin/#/',

  firebase: {
    apiKey: "AIzaSyANY6Rn7oE4BGfp2pYkTGODlifTl6lwQho",
    authDomain: "timberwarriors-43ff7.firebaseapp.com",
    projectId: "timberwarriors-43ff7",
    storageBucket: "timberwarriors-43ff7.appspot.com",
    messagingSenderId: "170853406912",
    appId: "1:170853406912:web:f2c03e32710ce826033525"
  }
  // baseUrl:'https://timberwarriorsapp.com/dev-vca',
  // mediaUrl: 'https://timberwarriors.s3.amazonaws.com/vca/claim-photos'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
