import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  ws: any;
  adminToken = '';
  connected = false;
  public msg: BehaviorSubject<any> = new BehaviorSubject<string>(null);
  constructor(private auth: AuthService) {
    this.adminToken = this.auth.token();
    this.websocketStrat(this.adminToken);
  }

  close() {
    this.ws.close();
  }
  status() {
    const status = this.ws.readyState;
    if (status === 3) {
      this.websocketStrat(this.auth.token());
    }
    return status;
  }

  websocketStrat(adminToken: string) {
    //for dev
    this.ws = new WebSocket('wss://timberwarriorsapp.com:9090');
    this.ws.onopen = (e: any) => {
      this.connected = true;
      this.Authorize(adminToken);
    };

    this.ws.onmessage = (evt: any) => {
      console.log('onmessagesocket', evt.data);

      this.msg.next(evt.data);
    };

    this.ws.onerror = (e: any) => {
      this.connected = false;
      setTimeout(() => {
        this.websocketStrat(this.adminToken);
      }, 15 * 1000);
      console.log('onerror', e);
    };

    this.ws.onclose = (evt: any) => {
      console.log('onclose', evt);
      this.connected = false;
      // setTimeout(() => {
      //   this.websocketStrat(this.adminToken);
      // }, 15 * 1000);
    };
  }
  Authorize(adminToken: string) {
    this.ws.send(
      JSON.stringify({ event: 'AuthorizeStream', data: { token: adminToken } })
    );
  }


  //for getting stream data
  getStreamData(id: number, type: string) {
    this.ws.send(
      JSON.stringify({
        event: 'Stream',
        data: {
          token: this.adminToken,
          module: type,
          module_id: id,
        }
      })
    );
  }

  //for add a stream comment
  saveStreamComment(id: number, type: string, description: string, tageedUser: any) {
    this.ws.send(
      JSON.stringify({
        event: 'PostStream',
        data: {
          token: this.adminToken,
          module: type,
          module_id: id,
          description: description,
          user_id: this.auth.user().id,
          users: tageedUser
        }
      })
    );
  }


  //for send text msg
  sendChatMessage(claimId, assigmnetId, value) {
    this.ws.send(
      JSON.stringify({
        event: 'message',
        data: {
          claim_id: claimId,
          assignment_id: assigmnetId,
          user_type: 'vca',
          message_type: 'text',
          message: value,
        },
      })
    );
  }
}
