import { Component } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LoaderService } from './services/loader';
import { MessagingService } from "./services/messaging.service";
import { NotificationService } from './services/notification.service';
import { ToasterService } from './services/toaster.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TimeberWarriorscrm';
  loading = false;
  message: any;
  constructor(private loader: LoaderService,
    private router: Router,
    public messagingService: MessagingService,
    private toaster: ToasterService,
    private notification: NotificationService,
    private titleService: Title) { }
  ngOnInit() {
    // this.websocket.status();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    this.message = this.messagingService.currentMessage.subscribe((msg: any) => {
      console.log('msg', msg);

      if (msg) {
        const countBadge = Number(msg?.data?.badge);
        this.notification.notifiationCount({count:countBadge,notification:msg?.data});
        this.toaster.show('success', msg.notification.title, msg.notification.body,9000);
      }
    });


    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loader.state(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loader.state(false);
      }
    });



    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`${title}`);
        }
      });
  }


  ngAfterViewInit() {
    this.loader.attach.subscribe(data => {
      setTimeout(() => {
        this.loading = data;
      }, 100);
    });
  }
}
