import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private info = new BehaviorSubject<boolean>(false);
  public role = new BehaviorSubject<any>({});

  currentState = this.info.asObservable();

  constructor(
  ){}

  guest() {
    if (this.token() && this.user()) {
      this.state(false);
      return false;
    }
    this.state(true);
    return true;
  }

  user() {
    let user = localStorage.getItem('_timberWarriorCrmUser');

    return JSON.parse(user);
  }

  state(state: boolean) {
    this.info.next(state);
  }

  token() {
    return localStorage.getItem('timberWarriorCrmtoken') || '';
  }

  login(data: any) {
    this.state(true);
    const user = {
      id: data.data.id,
      user_type: data.data.user_type
    };
    let token = data.data.token;

    localStorage.setItem('_timberWarriorCrmUser', JSON.stringify(user));
    localStorage.setItem('timberWarriorCrmtoken', token);
  }

  logout() {
    // remove local storage

    localStorage.removeItem('_timberWarriorCrmUser');
    localStorage.removeItem('timberWarriorCrmtoken');
  }
}
