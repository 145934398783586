<!-- <div class="loader-container" *ngIf="show">
  <div class="spinner">
    Loading...<br>
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div> -->

<!-- <div class="loader-main" *ngIf="show">
  <div class='music'>
    <span class='line line1'></span>
    <span class='line line2'></span>
    <span class='line line3'></span>
    <span class='line line4'></span>
    <span class='line line5'></span>
    <p class="mt-4">Loading...</p>
  </div>
</div> -->

<!-- <div id="loader-wrapper" class="cardcontainer" *ngIf="show">
  <div id="loader">
    <img class="galleryImg" src="assets/images/logo.png">
  </div>
</div> -->

<div id="loader-wrapper" class="cardcontainer" *ngIf="show">
  <div id="loader">
    <img class="loader_img" src="assets/images/spinner.gif">
  </div>
</div>