import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuestGuard } from './guards/guest.guard';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
  {
    path: '',
    canActivate: [GuestGuard],
    loadChildren: () => import('./components/guest-layout/guest-layout.module').then(m => m.GuestLayoutModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/user-layout/user-layout.module').then(m => m.UserLayoutModule),
  },
  {
    path: '',
    loadChildren: () => import('./components/common-layout/common-layout.module').then(m => m.CommonLayoutModule),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
